import { defaultSchema, tf2021Schema } from './validationSchemas';
/**
 * NB!!! Ciascuna chiave dell'oggetto config deve
 coincidere con il pathname della pagina.
 *
 * Descrizione parametri:
 *
 * loginUrl: Url della pagina di login, il parametro viene usato in FormController per gestire i redirect.
 * Va SEMPRE aggiunto il punto interrogativo finale (saranno accodati dei parametri nella query string)
 *
 * formUrl: Url del form di registrazione, può essere formPay.html o form.html
 *
 * primoroundBaseUrl: Url di primo round, tutte le url vanno specificate anche nel file env
 *
 * from: Parametro query string inserito nel redirect dalla pagina login a quella di registrazione. Viene usata
 * per gestire il ritorno alla pagina di login dal form di registrazione una volta fatto il submit
 *
 * blacklistCategories: elenco delle categorie professionali da nascondere nel form di registrazione.
 */
const configs = {
  '/registrazionePlus.html': {
    loginUrl: 'registrazionePlus.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2023-Gennaio-${process.env.STAGE}/signup`,
    profileUrl: `Tf2023-Gennaio-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2023-Gennaio-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2023-Gennaio-${process.env.STAGE}/check`,
    hashUrl: `Tf2023-Gennaio-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    primoroundBaseUrl: process.env.PRIMOROUND_URL,
    from: 'regPlus',
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    errorText: `Gentile utente, questo contenuto è riservato agli abbonati di Telefisco PLUS.<br>
    Clicca <a href="##PAY_PATH##">qui</a> per acquistarlo,
    potrai accedere alla differita di Telefisco 2023, alle relazioni aggiuntive e potrai scaricare la dispensa con le relazioni e le slides di tutti gli interventi.`,
    lockDifferita: false,
    lockedDifferitaText: `Telefisco 2022 Plus e Advanced non sono più disponibili.<br>
    I contenuti formativi avevano validità fino al 31/12/2022.<br><br>
    È possibile registrarsi a <a href="##PAY_PATH##">Telefisco 2023</a> che si svolgerà in modalità digitale il 26/01/2023.`,
    blacklistCategories: [
      'crediti-notai',
      'crediti-altreprofessioni',
      'crediti-avvocati',
      'crediti-altreprofessioni',
    ],
  },
  '/webinar-001.html': {
    loginUrl: 'webinar-001.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web01',
    primoroundBaseUrl: process.env.WEBINAR_01_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-002.html': {
    loginUrl: 'webinar-002.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web02',
    primoroundBaseUrl: process.env.WEBINAR_02_PRIMOROUND_URL,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-003.html': {
    loginUrl: 'webinar-003.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web03',
    primoroundBaseUrl: process.env.WEBINAR_03_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinarPlus.html': {
    loginUrl: 'webinarPlus.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2023-Gennaio-${process.env.STAGE}/signup`,
    profileUrl: `Tf2023-Gennaio-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2023-Gennaio-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2023-Gennaio-${process.env.STAGE}/check`,
    hashUrl: `Tf2023-Gennaio-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'webPlus',
    primoroundBaseUrl: process.env.WEBCAST_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    errorText: `Gentile utente, questo contenuto è riservato agli abbonati di Telefisco PLUS.<br>
    Clicca <a href="##PAY_PATH##">qui</a> per acquistarlo,
    potrai accedere alla differita di Telefisco 2023, alle relazioni aggiuntive e potrai scaricare la dispensa con le relazioni e le slides di tutti gli interventi.`,
    lockDifferita: false,
    lockedDifferitaText: `Telefisco 2022 Plus e Advanced non sono più disponibili.<br>
    I contenuti formativi avevano validità fino al 31/12/2022.<br><br>
    È possibile registrarsi a <a href="##PAY_PATH##">Telefisco 2023</a> che si svolgerà in modalità digitale il 26/01/2023.`,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-consulenti-lavoro-tf2022',
      'crediti-notai',
      'crediti-altreprofessioni',
      'crediti-avvocati',
      'crediti-altreprofessioni',
    ],
  },
  '/webinar-004.html': {
    loginUrl: 'webinar-004.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Bonus, 110% e aiuti per ripartire – Speciale Telefisco: la differita',
    from: 'web04',
    primoroundBaseUrl: process.env.WEBINAR_04_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="##PAY_PATH##">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-005.html': {
    loginUrl: 'webinar-005.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Il bonus 110%: gli approfondimenti',
    from: 'web05',
    primoroundBaseUrl: process.env.WEBINAR_05_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="##PAY_PATH##">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-006.html': {
    loginUrl: 'webinar-006.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Gli aiuti alle imprese',
    from: 'web06',
    primoroundBaseUrl: process.env.WEBINAR_06_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="##PAY_PATH##">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-007.html': {
    loginUrl: 'webinar-007.html?',
    formUrl: 'formTf2021.html',
    signupUrl: `Tf2021-Giugno-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-Giugno-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-Giugno-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-Giugno-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-Giugno-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY_2021,
    payPath: process.env.PAY_PATH_2021,
    title: 'Speciale Telefisco - Gli ultimi controlli sulle dichiarazioni dei redditi',
    from: 'web07',
    primoroundBaseUrl: process.env.WEBINAR_07_PRIMOROUND_URL,
    errorText: `Gentile utente, questa utenza non consente l’accesso ai contenuti a pagamento dell’edizione speciale di Telefisco. Puoi scegliere a <a href="##PAY_PATH##">questo link</a> uno dei pacchetti premium con rilascio dei crediti formativi.`,
    BlockNonPlusUser: true,
    useHashpEndpoint: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-008.html': {
    // Evento 14 Giugno 2021
    loginUrl: 'webinar-008.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web08',
    primoroundBaseUrl: process.env.WEBINAR_08_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-009.html': {
    // Evento 12 Luglio 2021
    loginUrl: 'webinar-009.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web09',
    primoroundBaseUrl: process.env.WEBINAR_09_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-010.html': {
    // Evento 16 Settembre 2021
    loginUrl: 'webinar-010.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web10',
    primoroundBaseUrl: process.env.WEBINAR_10_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-011.html': {
    // Evento 14 Ottobre 2021
    loginUrl: 'webinar-011.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web11',
    primoroundBaseUrl: process.env.WEBINAR_11_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/webinar-012.html': {
    // Evento 18 Novembre 2021
    loginUrl: 'webinar-012.html?',
    formUrl: 'formPay.html',
    signupUrl: `Tf2021-${process.env.STAGE}/signup`,
    profileUrl: `Tf2021-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2021-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2021-${process.env.STAGE}/check`,
    hashUrl: `Tf2021-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    from: 'web12',
    primoroundBaseUrl: process.env.WEBINAR_12_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: defaultSchema,
    blacklistCategories: [
      'crediti-consulenti-lavoro',
      'crediti-avvocati',
      'crediti-notai',
      'crediti-giuristi',
      'crediti-tributaristi',
    ],
  },
  '/index.html': {
    // Evento Telefisco 2025 Gennaio
    isLive: process.env.IS_LIVE === 'true' || false,
    loginUrl: 'index.html?',
    formUrl: 'form.html',
    signupUrl: `Tf2025-Gennaio-${process.env.STAGE}/signupfree`,
    profileUrl: `Tf2025-Gennaio-${process.env.STAGE}/profile`,
    checkCduUrl: `Tf2025-Gennaio-${process.env.STAGE}/checkcdu`,
    checkUrl: `Tf2025-Gennaio-${process.env.STAGE}/check`,
    hashUrl: `Tf2025-Gennaio-${process.env.STAGE}/hashp`,
    duUrlPay: process.env.DU_URL_PAY,
    payPath: process.env.PAY_PATH,
    thankYouPath:
      process.env.IS_LIVE === 'true' ? process.env.STREAMING_URL : process.env.THANK_YOU_PATH,
    from: 'tf2025',
    primoroundBaseUrl: process.env.WEBINAR_12_PRIMOROUND_URL,
    BlockNonPlusUser: true,
    blockInvalidCduUser: true,
    validationSchema: tf2021Schema,
    blacklistCategories: [],
    errorText: `<h2 class="tf-section-title">Le iscrizioni a Telefisco 2025 sono chiuse.</h2>
    Puoi rivedere la differita dell’evento del 5 febbraio, acquistando <a href="##PAY_PATH##">Telefisco Advanced</a>.<br /><br />
    Con Telefisco Andvanced potrai, inoltre, partecipare alle sessioni formative di Master Telefisco fino al 30 aprile 2025 (un incontro a settimana della durata di due ore, disponibile anche in differita).<br />
    Per continuare a rimanere aggiornati sulle novità fiscali con gli esperti del Sole 24 Ore.`,
  },
};

export default configs;
